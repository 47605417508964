export default {
	nodeEnv: process.env.NODE_ENV ?? '',
	siteName: process.env.NEXT_PUBLIC_SITE_NAME ?? 'Keuken Kampioen Divisie',
	siteUrl: process.env.NEXT_PUBLIC_SITE_URL ?? '',
	apiUrl: process.env.NEXT_PUBLIC_API_URL ?? '',
	bugsnagApiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ?? '',
	flocklerSiteId: process.env.NEXT_PUBLIC_FLOCKLER_SITE_ID ?? '',
	flocklerEmbedId: process.env.NEXT_PUBLIC_FLOCKLER_EMBED_ID ?? '',
	gaMeasurementId: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID ?? '',
	onesignalAppId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID ?? '',
	brevoApiUrl: process.env.NEXT_PUBLIC_BREVO_API_URL ?? '',
	brevoApiKey: process.env.NEXT_BREVO_API_KEY ?? '',
	recaptchaSiteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? '',
	recaptchaSecretKey: process.env.NEXT_RECAPTCHA_SECRET_KEY ?? '',
	doApiUrl: process.env.NEXT_PUBLIC_DO_URL ?? '',
	allowedOrigins: process.env.NEXT_CORS_ALLOWED_ORIGINS ?? [],
	doAssetUrl: process.env.NEXT_PUBLIC_DO_ASSET_URL ?? '',
} as const;
