import React, { Fragment, useRef, useState } from 'react';
import Link from 'next/link';
import config from '../lib/config';
import Icon from './icon/Icon';

import { Dialog, Disclosure, Menu, Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '../utils/applyCssClassNames';
import { IMenuItem } from '../models/menu-item';
import { InvertedLinkButton } from './inverted-link-button';

interface IProps {
	items: IMenuItem[];
}

const timeoutDuration = 100;
let timeout: NodeJS.Timeout;

export default function NavBar({ items }: IProps) {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);
	const [isHovering, setIsHovering] = useState(false);

	const buttonRefs = useRef<Map<string, HTMLButtonElement | null>>(new Map()).current;

	const handleMainMouseEnter = (id: string) => {
		clearTimeout(timeout);
		setActiveSubmenu(id);
	};

	const handleMainMouseLeave = (_id: string) => {
		timeout = setTimeout(() => {
			if (!isHovering) {
				setActiveSubmenu(null);
			}
		}, timeoutDuration);
	};

	const handleSubmenuMouseEnter = () => {
		clearTimeout(timeout as NodeJS.Timeout);
		setIsHovering(true);
	};

	const handleSubmenuMouseLeave = (id: string) => {
		setIsHovering(false);
		setTimeout(() => {
			if (!buttonRefs.get(id)?.matches(':hover')) {
				setActiveSubmenu(null);
			}
		}, timeoutDuration);
	};

	return (
		<header className="sticky top-0 bg-red-500 md:shadow-xl z-50">
			<div className="container mx-auto">
				<nav className="mx-auto flex items-center justify-between p-6 lg:px-5 text-white" aria-label="Global">
					<div className="flex lg:flex-none lg:w-32 xl:w-36 relative">
						<Link href={`/`} className="-m-1.5 p-1.5 absolute -translate-y-6 lg:-translate-y-7">
							<span className="sr-only">Keuken Kampioen Divisie</span>
							<img
								className="w-24 max-w-none lg:w-auto lg:h-28"
								src={`${config.apiUrl}/wp-content/themes/kkd-headless/logo.png`}
								alt="Logo Keuken Kampioen Divisie"
							/>
						</Link>
					</div>
					<div className="flex lg:hidden">
						<button
							type="button"
							className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
							onClick={() => setMobileMenuOpen(true)}
						>
							<span className="sr-only">Open main menu</span>
							<Bars3Icon className="h-6 w-6" color="#FFFFFF" aria-hidden="true" />
						</button>
					</div>

					<Popover.Group className="hidden lg:flex lg:gap-x-3 lg:item-center xl:gap-x-8 2xl:gap-x-10">
						{items.map((menuItem) => (
							<Fragment key={menuItem.id}>
								{!menuItem.parentId && (
									<Popover className="relative">
										{({ open }) => (
											<div
												onMouseEnter={() => handleMainMouseEnter(menuItem.id)}
												onMouseLeave={() => handleMainMouseLeave(menuItem.id)}
												className="flex flex-col"
											>
												<Popover.Button
													className="focus:border-transparent focus:ring-1 outline-none"
													ref={(ref) => {
														// Check if the ref is not null
														if (ref) {
															// If the ref is not null, add it to the map
															buttonRefs.set(menuItem.id, ref);
														}
													}}
												>
													<div className="flex items-center gap-x-1 text-sm font-semibold uppercase text-white tracking-wide">
														<Link
															href={menuItem.uri}
															target={menuItem.target ?? undefined}
															className="text-sm font-semibold uppercase text-white tracking-widest"
														>
															<span>{menuItem.label}</span>
														</Link>
														{menuItem.childItems &&
															menuItem.childItems.nodes.length > 0 && (
																<ChevronDownIcon
																	className={classNames(
																		open ? 'rotate-180' : '',
																		'h-5 w-5 flex-none text-white',
																	)}
																	aria-hidden="true"
																/>
															)}
													</div>
												</Popover.Button>
												{menuItem.childItems && menuItem.childItems.nodes.length > 0 && (
													<Transition
														as={Fragment}
														show={activeSubmenu === menuItem.id}
														enter="transition ease-out duration-200"
														enterFrom="opacity-0 translate-y-1"
														enterTo="opacity-100 translate-y-0"
														leave="transition ease-in duration-150"
														leaveFrom="opacity-100 translate-y-0"
														leaveTo="opacity-0 translate-y-1"
													>
														<Popover.Panel className="absolute -left-8 top-full 50 mt-3 w-screen max-w-xs overflow-hidden rounded bg-white shadow-lg">
															<div
																className="p-4 border"
																onMouseEnter={handleSubmenuMouseEnter}
																onMouseLeave={() =>
																	handleSubmenuMouseLeave(menuItem.id)
																}
															>
																{menuItem.childItems.nodes.map((childItem) => (
																	<div
																		key={childItem.id}
																		className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
																	>
																		<div className="flex-auto">
																			<a
																				href={childItem.uri}
																				target={childItem.target ?? undefined}
																				className="block font-semibold text-black-500"
																			>
																				{childItem.label}
																				<span className="absolute inset-0" />
																			</a>
																		</div>
																	</div>
																))}
															</div>
														</Popover.Panel>
													</Transition>
												)}
											</div>
										)}
									</Popover>
								)}
							</Fragment>
						))}
					</Popover.Group>

					<div className="hidden lg:flex lg:flex-1 lg:justify-end divide-x">
						<div className="hidden 2xl:block">
							<InvertedLinkButton href={'/samenvattingen'} icon={'play'}>
								Samenvattingen
							</InvertedLinkButton>
						</div>
						<Link href={'/zoeken'} className="divide-x ml-4 p-1.5">
							<Icon icon={'search'} color="white" size={24} />
						</Link>
					</div>
				</nav>

				{/* // Mobiel menu */}
				<Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
					<div className="fixed inset-0" />
					<Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
						<div className="flex items-center justify-end">
							<button
								type="button"
								className="-m-2.5 rounded-md p-2.5 text-gray-700"
								onClick={() => setMobileMenuOpen(false)}
							>
								<span className="sr-only">Close menu</span>
								<XMarkIcon className="h-6 w-6" aria-hidden="true" />
							</button>
						</div>
						<div className="mt-6 flow-root">
							<div className="-my-6 divide-y divide-gray-500/10">
								<div className="space-y-2 py-6">
									<Link
										href={'/zoeken'}
										className="flex w-full items-center justify-between rounded-lg py-2 pl-0 pr-3.5 text-base font-semibold leading-7 text-black-500 hover:bg-gray-50"
									>
										Zoeken
									</Link>
									<Link
										href={'/'}
										className="flex w-full items-center justify-between rounded-lg py-2 pl-0 pr-3.5 text-base font-semibold leading-7 text-black-500 hover:bg-gray-50"
									>
										Home
									</Link>
									{items.map((menuItem) => (
										<Disclosure as="div" className="-mx-3" key={menuItem.id}>
											{({ open }) => (
												<>
													{!menuItem.parentId && (
														<div>
															<Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-black-500 hover:bg-gray-50">
																<Link
																	href={menuItem.uri}
																	target={menuItem.target ?? undefined}
																>
																	{menuItem.label}
																</Link>
																{menuItem.childItems &&
																	menuItem.childItems.nodes.length > 0 && (
																		<ChevronDownIcon
																			className={classNames(
																				open ? 'rotate-180' : '',
																				'h-5 w-5 flex-none',
																			)}
																			aria-hidden="true"
																		/>
																	)}
															</Disclosure.Button>
															{menuItem.childItems &&
																menuItem.childItems.nodes.length > 0 && (
																	<Disclosure.Panel className="mt-2 space-y-2">
																		{menuItem.childItems.nodes.map((childItem) => (
																			<Disclosure.Button
																				key={childItem.id}
																				as="a"
																				href={childItem.uri}
																				className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-black-500 hover:bg-gray-50"
																			>
																				{childItem.label}
																			</Disclosure.Button>
																		))}
																	</Disclosure.Panel>
																)}
														</div>
													)}
												</>
											)}
										</Disclosure>
									))}
								</div>
							</div>
						</div>
					</Dialog.Panel>
				</Dialog>
			</div>
		</header>
	);
}
